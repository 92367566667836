import React, { useEffect }  from 'react'
import './Projectmain.css'
import NavBar from '../Navbar/Navbar'
import Gallery from './Gallery';
import { useTranslation } from 'react-i18next';
const Projectmain = () => {
  const { t} = useTranslation('global');
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
         <NavBar className="project-custom-navbar" />
        <h1 className='project-head'>{t('Projects')}</h1>
        <Gallery />
    </div>
  )
}

export default Projectmain