import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import './Interiorexterior2.css';
import Carousel from 'react-bootstrap/Carousel';
import interiorexterior21 from './images/SUMMER HOUSE-1.webp'
import interiorexterior22 from './images/SUMMER HOUSE-2.webp'
import interiorexterior23 from './images/SUMMER HOUSE-3.webp'
import interiorexterior24 from './images/SUMMER HOUSE-4.webp'
import interiorexterior25 from './images/SUMMER HOUSE-5.webp'

const Interiorexterior2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
    <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* .......................................... Detail Journal page ................................................ */}
      <div className="container-fluid">
        <div className="inner-container-interiorexterior2">
        <div className="reg-text interiorexterior2-headtext">
                        <h3>INTERIOR EXTERIOR</h3>
                         <h3>20/05/23</h3>
                     </div>
        </div>
        <div className="interiorexterior2-container2">
        <Carousel data-bs-theme="dark" className="text-center" indicators={false}>
  <Carousel.Item>
    <img
      className="d-block mx-auto"
      src={interiorexterior21}
      alt="First slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block mx-auto"
      src={interiorexterior22}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block mx-auto"
      src={interiorexterior23}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block mx-auto"
      src={interiorexterior24}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block mx-auto"
      src={interiorexterior25}
      alt="Third slide"
    />
  </Carousel.Item>
 
</Carousel>

        </div>
      </div>
    </div>
  )
}

export default Interiorexterior2