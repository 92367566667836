import React, { useEffect }  from 'react'
import NavBar from '../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import "./Design.css"
import design1 from './DesignImages/Designmain1.webp'
import design2 from './DesignImages/Designmain2.webp'

import design3 from './DesignImages/Designmain3.webp'
const Design = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      <div className="container-fluid Design-main-container">
              <div className="inner-container-Design">
                <div className="row Design-first-row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-7 inner-first-col-Design">
                      <div className="reg-text">
                        <h3>DESIGN</h3>
                         <h3>12/01/07</h3>
                     </div>
                     <img className='Design1-img' src={design1} alt="" srcset="" />
                     <div className='Design1-para-div'><p>We believe in trying to source locally for environmental and social consciousness as responsible entrepreneurs and designers. </p></div>
                     <div className='artisanal-link-div'><Link to="/Artisanal" className='Design11-link'>Click here to read more</Link></div>
                     </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 inner-second-col-Design">
                    <div className="reg-text">
                        <h3>DESIGN</h3>
                         <h3>10/07/24</h3>
                     </div>
                    <div className='Design2-img-div'>
                      <img className='Design2-img' src={design2} alt="" srcset="" />
                    </div> 
                     <p>We create a lot more unique designs that sing with soul when we design on paper. Featured here are a few of the sketches by Ar. Karthikeyan.</p>
                     <Link to="/Handsketch" className='Design2-link'>Click here to read more</Link>
                     <div className="row Design2-col-inner-row">
                        <div className="col-12 col-sm-12 col-md-12 Design2-col-inner-col1">
                        <div className="reg-text">
                        <h3>DESIGN</h3>
                         <h3>20/05/23</h3>
                     </div>
                     <div className="Design3-img-div"><img className='Design3-img' src={design3} alt="" srcset="" /></div>
                     <p>We design all types of products and design furniture, lifestyle products in a multidisciplinary platform by combining wood, glass, metal and paint to create stunning pieces of art that are one of a kind. </p>
                     <div className='turya-designpage-link-div'><Link to="/Turya" className='Design3-link'>Click here to read more</Link></div>
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-6 Design2-col-inner-col2">
                        <div className="reg-text">
                        <h3>PUBLICATIONS</h3>
                         <h3>01/01/09</h3>
                     </div>
                    <div className="Design4-img-div"><img className='Design4-img' src={publcation4} alt="" srcset="" /></div> 
                     <p>Century Apparels, a state of the art garment factory and corporate office designed in Tamil Nadu, showcased Society Interiors.</p>
                     <Link to="/Design4" className='Design4-link'>Click here to read more</Link>
                        </div> */}
                     </div>
                    </div>
                </div>
              </div>
      </div>
    </div>
  )
}

export default Design