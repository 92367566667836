import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Materiality.css'
import Materiality1 from './images/_DSC8494.webp'
import Materiality2 from './images/_DSC8497 1.jpg'
import Materiality3 from './images/_DSC8559.jpg'
import Materiality4 from './images/_DSC8757.webp'

const Materiality = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>MATERIALITY IN SPACE</h3>
                         <h3>10/03/24</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Materiality2">
          <div className="row Materiality-container2-row">
            <div className="col-8 Materiality-container2-col1">MATERIALITY IN SPACE</div>
            <div className="col-4 Materiality-container2-col2"><img src={Materiality1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Materiality3">
          <div className="row Materiality-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Materiality-container3-col1"><img src={Materiality3} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Materiality-container3-col2">
              
                <h2 className='mt-2 inner-head'>MATERIALITY IN SPACE</h2>
                <div className="row">
                    <div className="col"><p className='mt-1'>Let's talk about materiality in architecture and design. Juhani Pallasma famously said that the "eyes are the skin in architecture" , we touch and see a place through the eyes first, we take in the world around us through the eyes first.  </p>
                                         <p className='mt-1'>Although architecture and spatial design has traditionally been viewed as an ocularcentric profession, limiting the role of sight to just what is seen in space but the eyes go beyond the function of vision, evoking a multi-sensorial experience of touch, feel, memory, smell and taste as well.  Materiality and tactility is crucial in evoking this multi sensorial experience in space.  </p>
                                         <p className='mt-1'>What are your thoughts about materiality and multisensorial architecture?</p>
                    
                    </div>
                    <div className="col">
                    
                    <img className='thoughts4-img3 w-100' src={Materiality2} alt="" srcset="" />
                        
                   
                    </div>
                    {/* */}
                </div>
                
                
               
               
            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Materiality