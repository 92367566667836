import React from 'react'
import NavBar from '../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import "./Thoughts.css"
import thoughts1 from './images/thoughts1.webp'
import thoughts2 from './images/thoughts2.webp'
import thoughts3 from './images/thoughts3.jpg'
import thoughts4 from './images/thoughts4.webp'
const Thoughts = () => {
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      <div className="container-fluid Thoughts-main-container">
              <div className="inner-container-Thoughts">
                <div className="row Thoughts-first-row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 inner-first-col-Thoughts">
                      <div className="reg-text">
                        <h3>THOUGHTS</h3>
                         <h3>15/06/24</h3>
                     </div>
                     <img className='Thoughts1-img founder-img' src={thoughts1} alt="" srcset="" />
                   <div className='thoughts1-para'> <p >Join us to hear more about our founders thoughts on architecture, design and life. Kathikeyan Padmanabhan, a seasoned practioner embodies a wholistic practice of art, architecture, philosophy and spirituality in his profession.</p></div> 
                     <div className='founderthoght-link-div thoughts-readmore-link'></div><Link to="/Thoughts1" className='Thoughts1-link'>Click here to read more</Link>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 inner-second-col-Thoughts">
                    <div className="reg-text">
                        <h3>THOUGHTS</h3>
                         <h3>14/04/24</h3>
                     </div>
                    <div className='thoughts2-div'><img className='Thoughts2-img' src={thoughts3} alt="" srcset="" /></div> 
                     <p>The staircase, referred to as the symbolic spine of the house is the core around which a lot of the spaces and functions revolve...</p>
                     <Link to="/Thoughts2" className='Thoughts2-link thoughts-readmore-link'>Click here to read more</Link>

                     <div className="row Thoughts2-col-inner-row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 Thoughts2-col-inner-col1">
                        <div className="reg-text">
                        <h3>THOUGHTS</h3>
                         <h3>03/06/24</h3>
                     </div>
                    <div className='thoughts3-img-div'><img className='Thoughts3-img' src={thoughts2} alt="" srcset="" /></div> 
                     <p>What establishes the Genus Loci of a place? Read more to discover our thoughts on the soul of space.</p>
                     <Link to="/Thoughts3" className='Thoughts3-link thoughts-readmore-link'>Click here to read more</Link>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 Thoughts2-col-inner-col2">
                        <div className="reg-text">
                        <h3>THOUGHTS</h3>
                         <h3>10/03/24</h3>
                     </div>
                     <div className='thougts4-img-div'><img className='Thoughts4-img' src={thoughts4} alt="" srcset="" /></div>
                     <p> Materiality and tactility is crucial in evoking this multi sensorial experience in space. </p>
                    <div className='materiality-link-div'> <Link to="/Thoughts4" className='Thoughts4-link'>Click here to read more</Link></div>
                        </div>
                     </div>
                    </div>
                </div>
              </div>
      </div>
    </div>
  )
}

export default Thoughts