import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { galleryItems } from '../../Gallery';
import './Colorhouse.css';
import NavBar from '../../../Navbar/Navbar';
import color1 from '../DetailProjectpageImages/colorhouse1.webp';
import color2 from '../DetailProjectpageImages/colorhouse2.webp';
import color4 from '../DetailProjectpageImages/colorhouse4.webp';
import color5 from '../DetailProjectpageImages/colorhouse5.webp';
import color6 from '../DetailProjectpageImages/colorhouse6.webp';
import plus from '../DetailProjectpageImages/plus.webp';
import otherproject1 from '../../Project-images/P5.webp'
import otherproject2 from '../../Project-images/P6.webp'
import otherproject3 from '../../Project-images/P7.webp'
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Colorhouse = () => {
    const { t} = useTranslation('global');

    const { id } = useParams();
    const project = galleryItems.find(item => item.id === parseInt(id, 10));
    
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [id]);
    
  return (
    <div>
      <NavBar className="project-custom-navbar" />
      <div className="detail-page">
       <LazyLoadImage className='color1' src={color1} alt="" />
       <LazyLoadImage className='color2' src={color2} alt="" />
      </div>
      <div className="second-container-detailpage-color">
       
      </div>
      <div className="detail-page-contain3  container-fluid">
        
        <LazyLoadImage className='color4' src={color4} alt="" />
        <LazyLoadImage className='color5' src={color5} alt="" />
        <LazyLoadImage className='color6' src={color6} alt="" />
      </div>
      <div className="container-fluid info-contain mt-1">
        <div className="row justify-content-center">
          <div className="col-10 col-sm-8 col-md-4 detailproject-col">
            {/* <h2>{project.category}</h2>
            <p>Project ID - {project.id}</p>
            <p>Description for the project can go here...</p> */}
           <h2>{t('colorhouse')}</h2>
            <h6>{t('Typology')}<span className='specialcharacter'>- </span>{t('colorhouse-typology')}</h6>
            <h6>{t('Year')}<span className='specialcharacter'>- </span> {t('colorhouse-year')}</h6>
            <h6>{t('Area')}<span className='specialcharacter'>- </span>  {t('colorhouse-area')}</h6>
            <h6>{t('Location')}  <span className='specialcharacter'>- </span> {t('colorhouse-Location')}</h6>
            <p>{t('colorhouse-para')}
            </p>
            <p>{t('colorhouse-para2')}
            </p>
          </div>
        </div>
      </div>
      <div className="otherprojects-contain container-fluid">
        <div className='other-head'>
          <LazyLoadImage className='plus-img' src={plus} alt="" /> {t('other-projects')}
        </div>
        <div className="row otherproject-row justify-content-start mt-2">
         
            <div className="col-10 col-sm-10 col-md-4 col-xl-4 otherprojects">
                <Link to="/project/5"><LazyLoadImage className='other-project-img' src={otherproject1}  /> </Link>
            </div>
            <div className="col-10 col-sm-10 col-md-4 col-xl-4 otherprojects">
            <Link to="/project/6"><LazyLoadImage className='other-project-img' src={otherproject2}  /> </Link>
            </div>
            <div className="col-10 col-sm-10 col-md-4 col-xl-4 otherprojects">
            <Link to="/project/7"><LazyLoadImage className='other-project-img' src={otherproject3}  /> </Link>
            </div>
         
        </div>
      </div>
    </div>
  );
};

export default Colorhouse;



