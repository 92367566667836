import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Foaid.css'
import Foaid1 from './images/IMG_0345.jpg'
import Foaid2 from './images/IMG_0336.png'
import Foaid3 from './images/IMG_0113.jpg'

const Foaid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>FOAID 24'</h3>
                         <h3>03/06/24</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Foaid2">
          <div className="row Foaid-container2-row">
            <div className="col-8 Foaid-container2-col1">FOAID 24'</div>
            <div className="col-4 Foaid-container2-col2"><img src={Foaid1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Foaid3">
          <div className="row Foaid-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Foaid-container3-col1"><img src={Foaid1} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Foaid-container3-col2">
              
                <h2 className='mt-2 inner-head'>FOAID 24'</h2>
                <div className="row">
                    <div className="col"><p className='mt-2'>Our principal architect and founder, Karthikeyan had the pleasure of addressing the topic "Discovering the Soul of Architecture" at the Design Perspective held at Coimbatore. The line up had an impressive lineup of established professionals and designers at the conference with interesting guest speakers and industry members.
                        </p>
                        <p className='mt-2'> We spoke about the importance of "Genus Loci" which is the spirit of a place and how architects play a crucial role in creating the spirit of a place. Emotional and multi-sensory architecture is an integral part in how we design at our studio and this seemed like a fitting topic to address to the audience.
                    </p>
                    <p className='mt-2'>Stay tuned to see more about what we are upto and other topics such as the "Spirit of Architecture" and more! </p>
                        </div>
                    <div className="col">
                    
                    </div>
                </div>
                
                {/* <img className='mt-2' src={Foaid2} alt="" srcset="" /> */}
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Foaid