import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Handsketch.css'
import Handsketch1 from './Images/handsketch1.jpg'
import Handsketch2 from './Images/handsketch3.JPG'
import Handsketch3 from './Images/handsketch-1.png'

const Handsketch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>HAND SKETCHED DESIGNS</h3>
                         <h3>18/04/23</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Handsketch2">
          <div className="row Handsketch-container2-row">
            <div className="col-8 Handsketch-container2-col1">HAND SKETCHED DESIGNS</div>
            <div className="col-4 Handsketch-container2-col2"><img src={Handsketch1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Handsketch3">
          <div className="row Handsketch-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Handsketch-container3-col1"><img src={Handsketch2} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Handsketch-container3-col2">
              
                <h2 className='mt-2 inner-head-avinashi inner-head'>HAND SKETCHED DESIGNS</h2>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-7"><p className='mt-2'>HOW DO WE DESIGN?</p>
                    <p className='mt-1'>How do we design at our studio? Our principal architect and founder, Karthikeyan Padmanabhan still believes in putting pen to paper and he personally hand sketches all our designs and then they are converted into digital drawings and 3D visualizations for our presentations. He believes that his creative process is at its purest when he sits down and designs on paper and our designs speak to this. We create a lot more unique designs that sing with soul when we design on paper. Featured here are a few of the sketches by Ar. Karthikeyan.
                        </p></div>
                    <div className="col-12 col-sm-12 col-md-5">
                    <img className='mt-2 w-100' src={Handsketch3} alt="" srcset="" />
                       </div>
                </div>
                
                
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Handsketch