import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Foundersthought.css'
import Foundersthought1 from './images/thoughts1.webp'
import Foundersthought2 from './images/titleimage.png'

const Foundersthought = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>FOUNDERS THOUGHT</h3>
                         <h3>15/06/24</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Foundersthought2">
          <div className="row Foundersthought-container2-row">
            <div className="col-8 Foundersthought-container2-col2"><h1>FOUNDERS THOUGHTS</h1></div>
            {/* <div className="col-4 Foundersthought-container2-col2"><img src={Foundersthought2} alt="" srcset="" /></div> */}
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Foundersthought3">
          <div className="row Foundersthought-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Foundersthought-container3-col1"><img src={Foundersthought1} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Foundersthought-container3-col2">
              
                <h2 className='mt-2 inner-head'>FOUNDERS THOUGHT</h2>
                <div className="row">
                    <div className="col"><p className='mt-2'>Our founder and principal architectural designer, Karthikeyan Padmanabhans reflects on design and architecture, and a snippet of how he views the world around him.</p>
                    <p className='mt-2'>Growing up, a large part of my childhood was me with my paper and color pencils, creating the world around me onto paper with vivid illustrations as well as my imagination into sketches and paintings. I grew up in a fast paced household, with a big joint family and with my father being a doctor who travelled and kept shifting bases, I got the opportunity to see multiple places and cultures intimately in their varying forms, shapes and beliefs. I moved fluidly between the spaces as they kept morphing into cities, rural villages, the mountains, the seaside with the anchor of my grandmothers ancestral home in the heart of southern Tamil Nadu, Kumbakonam which is a cradle of warmth, comfort amidst an amalgamation of  culture, religion, art and lush agricultural fields on the banks of Cauvery. </p>
                    <p className='mt-2'>All these experiences add to who I am today as a designer and it is very much reflected in what we create at Vishwakarma Design Studio. The connection and powerful relationship between the built and natural environment is the crux of our practice and I strive to foster the connection between humans and nature through architecture. </p>
                    </div>
                    <div className="col">
                        <p className='mt-2'> People and their stories also fascinate me. As an architect and designer, I often get glimpses and whispers of people and what makes them who they are. It is a beautiful to craft spaces that speak to them, and their dreams and hopes.</p>
                        <p className='mt-2'> I realise that life is an journey of experiences, an amalgamation of moments, memories and encounters and that the spaces, buildings and products we create are stamped in the universe by time, life breathed in by the users, and stay far after we, the architects have created, travelling through time, coexisting with nature and science, narrating our stories, reflecting the artist in us, an uninterrupted flow of creation...</p>
                    </div>
                </div>
                
               
               
            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Foundersthought