import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Spatial.css'
import spatial1 from './Images/GOPR3568.webp'
import spatial2 from './Images/_DSC8453.jpg'
import spatial3 from './Images/_DSC8469-HDR.jpg'

const Spatial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>SPATIAL PLANNING</h3>
                         <h3>18/04/23</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Artisanaldesign2">
          <div className="row Spatial-container2-row">
            <div className="col-8 Spatial-container2-col1">SPATIAL PLANNING</div>
            <div className="col-4 Spatial-container2-col2"><img src={spatial1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Artisanaldesign3">
          <div className="row Spatial-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Spatial-container3-col1"><img src={spatial3} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Spatial-container3-col2">
              
                <h2 className='mt-2 inner-head-avinashi inner-head'>SPATIAL PLANNING</h2>
                <div className="row">
                    <div className="col"><p className='mt-2'>How do we spatially plan and design? 
                    </p>
                    <p className='mt-1'>This is a very wide and large subject to approach in design, how do we design and create? We aim to give a small insight into the topic by some of the key considerations we take as well as reflecting on ethos and principles at our practice as well. </p>
                    <p className='mt-1'>1. Light - Light is the primary and most instinctive principle we have in our practice. The inclusion of natural light is crucial to emotional, mental and physical wellbeing. Light is held sacred and breathes life into all living beings. We create with light as the focus in our design and planning. </p>
                    <p className='mt-1'>2. Natural Ventilation - Natural ventilation and circulation of fresh air is crucial in our design and planning. Vernacular architecture is the usage of local materials and adapting to the local environment and climate when designing a building, another core principle in our design. </p>
                    <p className='mt-1'>3. Central circulation - One of the iconic features we are known for are the courtyards we include in our spatial planning. The courtyard has an intangible quality that is nostalgic, and a nod to the old style of traditional architecture in India as well as the influx of light and air that is brought in through a courtyard. The central courtyard also informs the circulation in a space and evokes everyone to the focal point as a gathering space.</p>
                    
                    
                                        </div>
                    <div className="col">
                    <p className='mt-1'>4. Designer -  When we say designer, we mean that each space has an unique spatial quality architecturally and in interiors. We carve design identities for each building, home or space we design and that we are proud to say that no two buildings have the same design.</p>
                    <p className='mt-2'>5. Familiarity - A space has the power to evoke memories, to make someone feel safe, to be a source of inspiration. Creating a space with intention is important to us. </p>
                    <p className='mt-1'>6. Love - All our work is from the heart, we put our soul and effort into creating for our clients and for ourselves. We believe that when we create from a source of love, we cannot go wrong. </p>
                    <p className='mt-1'>7. Functionality - It goes without saying that functionality is integral to any design. Functionality meeting aesthetics is the fine line a designer plays in spatial planning. </p>
                    <p className='mt-1'>8. Scale and ratio -  This is an important aspect of design, where scale, proportion and ratio are important considerations while designing a space. How elements interact with one another, the meaning and context they give to each other should be focused on.</p>
                </div>
                <img className='w-100' src={spatial2} alt="" srcset="" /> 
                </div>
                
              
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spatial