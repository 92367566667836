import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Artisanaldesign.css'
import Artisanaldesign1 from './Images/ka063 (1).webp'
import Artisanaldesign2 from './Images/WE_P0247.jpg'
import Artisanaldesign3 from './Images/_DSC8410.webp'
import Artisanaldesign4 from './Images/ka073 (1).webp'
const Artisanaldesign = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>ARTISANAL DESIGN</h3>
                         <h3>18/04/23</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Artisanaldesign2">
          <div className="row Artisanaldesign-container2-row">
            <div className="col-8 Artisanaldesign-container2-col1">ARTISANAL DESIGN</div>
            <div className="col-4 Artisanaldesign-container2-col2"><img src={Artisanaldesign3} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Artisanaldesign3">
          <div className="row Artisanaldesign-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Artisanaldesign-container3-col1"><img src={Artisanaldesign2} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Artisanaldesign-container3-col2">
              
                <h2 className='mt-2 inner-head-avinashi inner-head'>ARTISANAL DESIGN</h2>
                <div className="row">
                    <div className="col"><p className='mt-2'>Artisanal design. We work with a lot of native artisans in our architectural and interior design. Over the past 30 years we have had the opportunity and privilege to meet incredible artists, craftsman that have shown immense skill and talent which have translated into our projects as well.</p>
                    <p className='mt-1'>Why do we work with artisans? The first reason being that it resonates with the ethos of our firm. From the beginning, we believe that design and spaces are personal and should reflect a deep belonging. A belonging to the space and its users is the life force of the building or space. By infusing our designs with artisanal design, it creates belonging and context to the design.
                        </p></div>
                    <div className="col"><p className='mt-2'>The next reason is to bring livelihoods to the artisans. A lot of marginalised and dwindling groups get exposure and livelihoods through the work on the projects we do. We believe in trying to source locally for environmental and social consciousness as responsible entrepreneurs and designers.</p>
                    <p className='mt-1'>Finally, we’d like to conclude this by adding that India is a vast and culturally diverse country with a lot of different artistic styles and forms of art and design. We get to celebrate our cultural tapestry through our profession which brings us pride. 

                </p>
                </div>
                </div>
                
                <img className='mt-2 w-100' src={Artisanaldesign4} alt="" srcset="" />
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Artisanaldesign