import React, { useEffect }  from 'react'
import NavBar from '../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import "./Planning.css"
import plan1 from './Images/_F6B2166.webp'
import plan2 from './Images/GOPR3568.jpg'
const Planning = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      <div className="container-fluid Planning-main-container">
              <div className="inner-container-Planning">
                <div className="row Planning-first-row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 inner-first-col-Planning">
                      <div className="reg-text">
                        <h3>Planning</h3>
                         <h3>12/01/07</h3>
                     </div>
                     <div className="planning1-img-div"><img className='planning1-img' src={plan2} alt="" srcset="" /></div>
                     <div className='planning1-para-div'><p>Looking at the Earth as “ancestoral” and “technology” as the future. The Earth is something we've inherited, deserving of the respect and care.</p></div>
                     <Link className='spatial-link' to="/theSpatial">Click to read more</Link>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 inner-second-col-Planning">
                    <div className="reg-text">
                        <h3>Planning</h3>
                         <h3>10/07/24</h3>
                     </div>
                    <div className='planning2-img-div'><img className='planning2-img' src={plan1} alt="" srcset="" /></div> 
                     <p>A space has the power to evoke memories, to make someone feel safe, to be a source of inspiration. Creating a space with intention is important to us.</p>
                     <Link to="/Environment" className='planning2-link'>Click here to read more</Link>
                     
                    </div>
                </div>
              </div>
      </div>
    </div>
  )
}

export default Planning