import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Functionality.css'
import Functionality1 from './images/DSC01258-HDR.jpg'
import Functionality2 from './images/DSC01261.webp'
import Functionality3 from './images/thoughts2-img2.jpg'
import Functionality4 from './images/DSC01398.webp'
import Functionality5 from './images/DSC01399.webp'
import Functionality6 from './images/DSC01415.webp'
import Functionality7 from './images/DSC01530.webp'
import Functionality8 from './images/DSC01531.webp'

const Functionality = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>FUNCTIONALITY AND SOUL IN DESIGN</h3>
                         <h3>14/04/24</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Functionality2">
          <div className="row Functionality-container2-row">
            <div className="col-8 Functionality-container2-col1">FUNCTIONALITY AND SOUL IN DESIGN </div>
            <div className="col-4 Functionality-container2-col2"><img src={Functionality1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Functionality3">
          <div className="row Functionality-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Functionality-container3-col1"><img src={Functionality3} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Functionality-container3-col2">
              
                <h2 className='mt-2 inner-head'>FUNCTIONALITY AND SOUL IN DESIGN </h2>
                <div className="row">
                    <div className="col">
                      <p className='mt-2'>The staircase, a transitory space from one level to another in building design. In our studio, we look at this transitory space as an opportunity to design another aspect of a space that can infuse more life into what we envision for the space.</p>
                      <p className=''>The staircase, referred to as "the symbolic spine of the house" is the core around which a lot of the spaces and functions revolve. We create to qualitatively and quantitatively add to functional space to make it holistic, functional and aesthetic.</p>
                        <p> Featured here is the staircase at the Chiaroscuro House, in Coimbatore. </p>
                    {/* <img className='functoinality3-img' src={Functionality3} alt="" srcset="" /> */}
                    </div>
                    <div className="col">
                       
                        <img className='w-100 staircase-last-img' src={Functionality5} alt="" srcset="" />
                    </div>
                </div>
              
                
               
               
            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Functionality