import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Designperspective.css'
import Designperspective1 from './images/Recognitions2.webp'

const Designperspective = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>DESIGN PERSPECTIVE</h3>
                         <h3>09/08/23</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Designperspective2">
          <div className="row Designperspective-container2-row">
            <div className="col-8 Designperspective-container2-col1">DESIGN PERSPECTIVE</div>
            <div className="col-4 Designperspective-container2-col2"><img src={Designperspective1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Designperspective3">
          <div className="row Designperspective-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Designperspective-container3-col1"><img src={Designperspective1} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Designperspective-container3-col2">
              
                <h2 className='mt-2 inner-head'>DESIGN PERSPECTIVE AUGUST 2023’</h2>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8"><p className='mt-2'>Vishwakarma Design Studio recently had the honour of being presented with the “Outstanding & Prominent Architecture & Interior Design Firm of the Year 2023 under Residential Projects & Commercial Projects and Trusted Architect & Designer of the Year 2023!We are very excited and humbled to be included in this space. Our principal architects and founders, Karthikeyan Padmanabhan and Ruckmini Karthikeyan received the prestigious award.
                        </p></div>
                    <div className="col-12 col-sm-12 col-md-3"><p className='mt-2'></p></div>
                </div>
                
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Designperspective