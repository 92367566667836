import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Turya.css'
import Turya1 from './Images/Turya logo (1).webp'
import Turya2 from './Images/Chairs (1).webp'
import Turya3 from './Images/Chairs (2).webp'
import Turya4 from './Images/poster 3x3 2.webp'
import Turya5 from './Images/poster.webp'

const Turya = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>TURYA</h3>
                         <h3>18/04/23</h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Turya2">
          <div className="row Turya-container2-row">
            <div className="col-8 Turya-container2-col1">TURYA</div>
            <div className="col-4 Turya-container2-col2"><img src={Turya1} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Handsketch3">
          <div className="row Turya-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Turya-container3-col1"><img src={Turya2} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Turya-container3-col2">
              
                <h2 className='mt-2 inner-head-avinashi inner-head'>TURYA</h2>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-7"><p className='mt-2'>How did Turya come to be? </p>
                    <p className='mt-1'>Turya, is defined as the pure consciousness of the universe in Sanskrit, the state of being beyond the mortal realms. The individual soul realizes and transcends the three states of waking, dreaming, and deep sleep consciousness in turya. While the word has a deep spiritual meaning and is rooted in Indian culture, we named our furniture platform Turya to represent the designs and furniture we create as one of a kind and out of this world. When we design, it is spiritual to us as we design from within, by looking within and then drawing from the outside. Design is a holistic process at our studio and hence Turya was born.</p>
                    <p className='mt-1'>We design all types of products and design furniture, lifestyle products in a multidisciplinary platform by combining wood, glass, metal and paint to create stunning pieces of art that are one of a kind. Over the past 30 years we have carved a niche for ourselves as one of the leading and pioneers in creating custom pieces of furniture and products in South India. Our founders, Karthikeyan Padmanabhan and Ruckmini Karthikeyan began Turya as a young couple in 1995, at the inception of their design profession. Down the road, they began Vishwakarma Design Studio as their construction, architectural and design platform and Turya remained their product and furniture platform. Together, we create a complete, holistic brand entity for your building needs.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-5"> 
                      <img className='w-100 mt-2' src={Turya5} alt="" srcset="" />
                      <img className='w-100 mt-4' src={Turya4} alt="" srcset="" />
                    </div>
                </div>
                
                {/* <img className='mt-2 w-100' src={Handsketch4} alt="" srcset="" /> */}
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Turya