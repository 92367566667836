import React, { useEffect }  from 'react'
import NavBar from '../../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import './Genusloci.css'
import Genusloci1 from './images/k (1).jpg'
import Genusloci2 from './images/k (1).webp'
import Genusloci3 from './images/WE_P3955 1.png'
const Genusloci = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      {/* ............................................... Main Container .............................................................. */}
      <div className="container-fluid">
      <div className="inner-container-interiorexterior">
        <div className="reg-text interiorexterior-headtext">
                        <h3>GENUS LOCI</h3>
                         <h3>03/06/24
                         </h3>
                     </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Genusloci2">
          <div className="row Genusloci-container2-row">
            <div className="col-8 Genusloci-container2-col1">GENUS LOCI</div>
            <div className="col-4 Genusloci-container2-col2"><img src={Genusloci2} alt="" srcset="" /></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
      <div className="inner-container-Genusloci3">
          <div className="row Genusloci-container2-row">
            <div className="col-12 col-sm-12 col-md-5 Genusloci-container3-col1"><img src={Genusloci1} alt="" srcset="" /></div>
            <div className="col-12 col-sm-12 col-md-7 Genusloci-container3-col2">
              
                <h2 className='mt-2 inner-head'>GENUS LOCI</h2>
                <div className="row">
                    <div className="col"><p className='mt-2'>What establishes the Genus Loci of a place? It is the intangible quality that turns a space into a place, something that has a belonging, which is established through a various number of factors such as volume, materiality, colour, light, landscape, art and spirit. </p>
                                         <p className='mt-2'>As an architect and designer, establishing and integrating spirit and place is one of the most honouring journeys and at here Vishwakarmadesignstudio , we take this role very seriously.  </p>
                    
                    </div>
                    <div className="col">
                        <p className='mt-2'>The buildings and spaces we design are sacred and we believe that the energy of the space is key for prosperity and abundance. As a design practitioner, considering the #Genusloci of a space is an interesting design consideration and how it shapes the design and planning of our projects. It makes way for interesting and surprising process of discovery, which results in unique designs for our clients.</p>
                        
                    </div>
                    <img className='w-100 genusloci-last-img' src={Genusloci3} alt="" srcset="" />
                </div>
                
               
               
            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Genusloci