import React, { useEffect }  from 'react'
import NavBar from '../../Navbar/Navbar'
import { Link } from 'react-router-dom';
import "./Publications.css"
import publcation1 from './images/Publication1.webp'
import publcation2 from './images/Publicarion2.webp'
import publcation3 from './images/Publication3.webp'
import publcation4 from './images/Publication4.webp'
const Publications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <NavBar className="project-custom-navbar" />
      <div className="top-text">
        <div className="journal-menu"><Link to="/Journal">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      <div className="container-fluid publications-main-container">
              <div className="inner-container-publications">
                <div className="row publications-first-row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-7 inner-first-col-publication">
                      <div className="reg-text">
                        <h3>PUBLICATIONS</h3>
                         <h3>12/01/07</h3>
                     </div>
                     <img className='publication1-img' src={publcation1} alt="" srcset="" />
                     <div className='publication1-para-div'><p>Coimbatore based architects Karthick and his wife Ruckmani's home turya, combines a resort like feeling with the sophestication of a modern contemporary house...</p></div>
                    <div className="publication1-link-div"><Link to="/Publications1" className='publication11-link'>Click here to read more</Link></div> 
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 inner-second-col-publication">
                    <div className="reg-text">
                        <h3>PUBLICATIONS</h3>
                         <h3>10/07/24</h3>
                     </div>
                    <div className='publication2-img-div'><img className='publication2-img' src={publcation2} alt="" srcset="" /></div> 
                     <p>We designed this house for a young couple who are busy entrepreneurs and their sons to create a beautiful house for them to grow together...</p>
                     <Link to="/Publications2" className='publication2-link'>Click here to read more</Link>
                     <div className="row publication2-col-inner-row">
                        <div className="col-12 col-sm-12 col-md-6 publicaton2-col-inner-col1">
                        <div className="reg-text">
                        <h3>PUBLICATIONS</h3>
                         <h3>20/05/23</h3>
                     </div>
                     <div className="publication3-img-div"><img className='publication3-img' src={publcation3} alt="" srcset="" /></div>
                     <p>The Summer House is stunning residence featured in many platforms including the Interior Exterior magazine.</p>
                   <div className="publication3-link-div"> <Link to="/Publications3" className='publication3-link'>Click here to read more</Link></div> 
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 publicaton2-col-inner-col2">
                        <div className="reg-text">
                        <h3>PUBLICATIONS</h3>
                         <h3>01/01/09</h3>
                     </div>
                    <div className="publication4-img-div"><img className='publication4-img' src={publcation4} alt="" srcset="" /></div> 
                     <p>Century Apparels, a state of the art garment factory and corporate office designed in Tamil Nadu, showcased Society Interiors.</p>
                     <Link to="/Publications4" className='publication4-link'>Click here to read more</Link>
                        </div>
                     </div>
                    </div>
                </div>
              </div>
      </div>
    </div>
  )
}

export default Publications