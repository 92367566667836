import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import NavBar from '../Navbar/Navbar'
import "./Journal.css";
import thoughts1 from './Thoughts/images/thoughts1.webp'
import thoughts3 from './Thoughts/images/thoughts2.webp'
import recognitions2 from './Recognitions/Images/Recognitions2.webp'
import recognitions3 from './Recognitions/Images/Recognitions3.webp'
import turyalogo from './journalimages/Turya-logo.png'
import award1 from './journalimages/award.jpg'
import summerhouse from './journalimages/k (19).jpg'
import thoughts2 from './journalimages/thoughts3.jpg'
import thoughts4 from './Thoughts/images/thoughts4.webp'
import publications1 from './journalimages/Publication1.jpg'
import publications2 from './journalimages/Publicarion2.jpg'
import publications3 from './Publications/images/Publication3.webp'
import publications4 from './Publications/images/Publication4.webp'
import recognitions4 from './Recognitions/Images/Recognitions1.webp'
import sketch from './journalimages/2 (2).png'
import sketch2 from './journalimages/handsketch-1.png'

import Recognition4 from './Recognitions/Images/Recognition4.webp'

const Journal = () => {
  const [seeMoreVisible, setSeeMoreVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSeeMoreClick = () => {
    setSeeMoreVisible(!seeMoreVisible);
  };
  return (
   
 <>
    <NavBar className="project-custom-navbar" />
      <div className="top-text">
        
        <div className="journal-menu"><Link to="">All,</Link> <Link to="/Thoughts">THOUGHTS,</Link> <Link to="/Recognitions">RECOGNITIONS,</Link>  <Link to="/Publications">PUBLICATIONS,</Link> <Link to="/Planning">PLANNING,</Link> <Link to="/Design">DESIGN</Link>  </div>
        <h2 className="journal">JOURNAL</h2>
      </div>
      <div className="journal-main-container">
       
      <div className="inner-container-Thoughts">
               <div className="row">
               <div className="col-12 col-sm-12 col-md-12 col-lg-7 inner-first-col-Thoughts">
                      <div className="reg-text">
                        <h3>THOUGHTS</h3>
                         <h3>15/06/24</h3>
                     </div>
                     <img className='Thoughts1-img' src={thoughts1} alt="" srcset="" />
                   <div className='thoughts1-para'> <p >Join us to hear more about our founders thoughts on architecture, design and life. Kathikeyan Padmanabhan, a seasoned practioner embodies a wholistic practice of art, architecture, philosophy and spirituality in his profession.</p></div> 
                     <Link to="/Thoughts1" className='readmore-link'>Click here to read more</Link>

                     <div className="row Thoughts2-col-inner-row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 journal-main-first-col-in-row-col1">
                        <div className="reg-text reg-text-small-col">
                        <h3>RECOGNITIONS</h3>
                         <h3>03/06/24</h3>
                     </div>
                    <div className='thoughts3-img-div'><img className='Thoughts3-img' src={award1} alt="" srcset="" /></div> 
                     <p>Our principal architect and founder Karthikeyan Padmanabhan the Indian Institute of Architects at the Design Prespective at Coimbatore.</p>
                     <Link to="/Recognitions3" className='readmore-link'>Click here to read more</Link>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 genusloci-col">
                        <div className="reg-text reg-text-small-col">
                        <h3>THOUGHTS</h3>
                         <h3>03/06/24</h3>
                     </div>
                     <div className='thougts4-img-div'><img className='journal-thoughts3-img' src={summerhouse} alt="" srcset="" /></div>
                     <p> What establishes the Genus Loci of a place?
                     Read more to discover our thoughts on the soul of space. </p>
                    <div className="genusloci-linkdiv"><Link to="/Thoughts3" className='readmore-link genusloci-link'>Click here to read more</Link></div> 
                        </div>
                     </div>
                    </div>
                    
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 inner-second-col-journal-summer">
                      <div className="row inner-second-col-Thoughts-first-row summer-row">
                      <div className="reg-text ">
                        <h3>PUBLICATIONS </h3>
                         <h3>20/05/23</h3>
                     </div>
                    <div className='thoughts2-div'><img className='summerhouse-journal-img' src={publications3} alt="" srcset="" /></div> 
                     <p>The Summer House is stunning residence featured in many platforms including the Interior Exterior magazine.</p>
                     <Link to="/Publications3" className='journal-summer-house-link readmore-link'>Click here to read more</Link>
                      </div>
                    
                     <div className="row Thoughts2-col-inner-row">
                     
                     <h2 className="jounal-text-head-1">ARCHITECTURE AND SPACE  IS SACRED TO US.<br></br><br></br>WE SCULPT SPACES WITH SOUL.</h2>
                     </div>
                     <div className="row Thoughts2-col-inner-row">
                        <div className="col-12 col-sm-12 col-md-6 ">
                        <div className="reg-text reg-text-small-col">
                   
                         <h3>09/08/23</h3>
                     </div>
                    <div className='thoughts3-img-div'><img className='Thoughts3-img' src={recognitions2} alt="" srcset="" /></div> 
                     <p>Our design practice got a design award in 2023 for the most reliable practice.</p>
                     <div className="recognitionlink-2-div"><Link to="/Recognitions2" className='readmore-link recognitionlink-2'>Click here to read more</Link></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 journal-main-first-col-in-row-col2">
                        <div className="reg-text reg-text-small-col">
                       
                         <h3>13/06/24</h3>
                     </div>
                     <div className='thougts4-img-div'><img className='Thoughts4-img' src={turyalogo} alt="" srcset="" /></div>
                     <p> Turya is our design product and furniture platform we established in 1995, join us to learn more about our production platform. </p>
                     <div className="turya-link-div"><Link to="/Turya" className='readmore-link '>Click here to read more</Link></div>
                        </div>
                     </div>
                     
                    </div>
               
              </div>
             
      
              <div className="row second-main-row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                <div className="row journal-head-row"><h2 className="jounal-text-head-2">WE LOOK AT EVERY DESIGN THROUGH THE HEART AND THE HEAD.</h2></div>
                <div className="row"> <div className="reg-text ">
                        <h3>PUBLICATIONS</h3>
                         <h3>01/01/09</h3>
                     </div>
                    <div className='thoughts2-div'><img className='Thoughts2-img mt-4' src={publications4} alt="" srcset="" /></div> 
                     <p className="mt-4">Century Apparels, a state of the art garment factory and corporate office designed  in Tamil Nadu, showcased Society Interiors.</p>
                     <div className="century-link-div"><Link to="/Publications4" className='century-link readmore-link'>Click here to read more</Link></div></div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 second-main-col-in-sec-col sketch-col">
              <div className="row"> 
                <div className="reg-text ">
                        <h3>DESIGN</h3>
                         <h3>01/06/16</h3>
                     </div>
                    <div className='thoughts2-div'><img className='sketchimg1 mt-4' src={sketch} alt="" srcset="" /></div> 
                    <div className='thoughts2-div'><img className='sketchimg2 mt-4' src={sketch2} alt="" srcset="" /></div> 
                     <p className="mt-4">One of the things we are most proud of in our design practice is the handcrafted, unique designs we give birth to and bring life. We handsketch and curate buildings, spaces and furniture on every scale. When we put as much detail into every aspect of our design, it creates beautiful results...</p>
                    <div className="sketch-link-div"></div> <Link to="/Handsketch" className='readmore-link'>Click here to read more</Link></div>
              </div>
             </div>
      <div className="row" id="seemore-row1" style={{ display: seeMoreVisible ? 'flex' : 'none' }}>
        <div className="col-12 col-sm-12 col-md-4 journal-extra-col1">
        <div className="reg-text">
              <h3>THOUGHTS</h3>
              <h3>14/04/24</h3>
            </div>
          <div className="sketch-main"><img className="thoughts2-img-seemore mt-4" src={thoughts2} alt="" /></div>
            <p className="mt-4">
            The staircase, referred to as the symbolic spine of the house is the core around which a lot of the spaces and functions revolve...
            </p>
           <div className="staircase-link-div"> <Link to="/Thoughts2" className='readmore-link'>Click here to read more</Link></div>
        </div>
        <div className="col-12 col-sm-12 col-md-4 journal-extra-col2"> <div className="reg-text">
              <h3>PUBLICATIONS</h3>
              <h3>12/01/07</h3>
            </div>
          <div className="sketch-main"><img className="publications1-img-seemore mt-4" src={publications1} alt="" /></div>
            <p className="mt-4">
            Coimbatore based architects Karthick and his wife Ruckmani's home Turya, combines a resort-like feeling with the sophestication of a modern contemporary house...
            </p>
           <div className="shreyahome-div"><Link to="/Publications1" className='readmore-link'>Click here to read more</Link></div> 
            </div>
        <div className="col-12 col-sm-12 col-md-4 journal-extra-col3"> <div className="reg-text">
              <h3>RECOGNITIONS</h3>
              <h3>18/04/23</h3>
            </div>
          <div className="sketch-main"><img className="recognitions1-img-seemore mt-4" src={recognitions4} alt="" /></div>
            <p className="mt-4">
            Our principal architect Karthikeyan Padmanabhan and managing director Ruckmini Karthikeyan addressed the students about running an architectural practice and how to design with heart and soul.
            </p>
            <div className="avinashilingams-link-div"><Link to="/Recognitions1" className='readmore-link'>Click here to read more</Link></div>
            </div>
      </div>

      <div className="row" id="seemore-row2" style={{ display: seeMoreVisible ? 'flex' : 'none' }}> 
      <div className="col-12 col-sm-12 col-md-7 journal-extra-col4"> <div className="reg-text">
              <h3>PUBLICATIONS</h3>
              <h3>10/07/24</h3>
            </div>
          <div className="sketch-main"><img className="publications1-img-seemore mt-4" src={publications2} alt="" /></div>
            <p className="mt-4">
            We designed this house for a young couple who are busy entrepreneurs and their sons to create a beautiful house for them to grow together...
            </p>
            <Link to="/Publications2" className='readmore-link'>Click here to read more</Link>
            </div>
        <div className="col-12 col-sm-12 col-md-5 journal-extra-col5"> 
          <div className="reg-text">
              <h3>THOUGHTS</h3>
              <h3>10/03/24</h3>
            </div>
          <div className="sketch-main"><img className="recognitions1-img-seemore mt-4" src={thoughts4} alt="" /></div>
            <p className="mt-4">
            Materiality and tactility is crucial in evoking this multi sensorial experience in space. 
            </p>
           <div className="materialitylink-div"></div> <Link to="/Thoughts4" className='readmore-link'>Click here to read more</Link>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 journal-extra-col6">
              <div className="reg-text">
              <h3>RECOGNITIONS</h3>
              <h3>01/09/24</h3>
            </div>
          <div className="sketch-main"><img className="recognitions1-img-seemore mt-4" src={Recognition4} alt="" /></div>
            <p className="mt-4">
            Color House residence was nominated for the best use of color at the WADE ASIA festival this year.  
            </p>
            <div className="wade-link-div"></div><Link to="/Recognitions4" className='readmore-link'>Click here to read more</Link>
              </div>
            </div>
            </div>
      </div>
      
           
      </div>
      <div className="inner-seemore-contain">
      <Link to="#" className="seemore-link" onClick={handleSeeMoreClick}>
              <div className="seemore">
                {seeMoreVisible ? 'See Less' : 'See More'}
              </div>
            </Link>
      </div>
      
     </div>
    </>
  );
};

export default Journal;